.logo {
  height: 75px;
}

.App-link {
  color: #61dafb;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.hyperlink {
    background: none !important;
    border: none;
    padding: 0 !important;
    font-family: arial, sans-serif;
    color: #069;
    text-decoration: underline;
    cursor: pointer;
}

.center {
    text-align: center;
}

.rightColumn {
    width: 50%;
    text-align: left;
}

.alignright {
    text-align: left;
}

.leftColumn {
    text-align: right;
    display: inline;
    width: 50%;
    padding-right: 10px;
}

.row {
    display: inline-flex;
    flex-direction: row;
}

.column {
    display: inline-flex;
    flex-direction: column;
}

.spacer {
    width: 20%;
}

.hidden {
	display:none; 
	visibility:hidden;
}

.visible {
	display: block; 
	visibility: visible;
}

.redWarning {
    font-weight: bold;
    color: red;
}

.fill100 {
    box-sizing: border-box;
    width: 100%;
    padding: 5px;
}

.dropdown-content a:hover {
    background-color: #ddd;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropbtn {
    white-space: nowrap;
    border: 0;
    background-color: #fff;
}

.divScrollY {
    overflow-y:auto;
}

.loadedImage {
    height: 25px;
    width: 70px;
    display: block;
}